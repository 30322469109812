import { device } from "src/styles/breakpoints"
import { graphiteOne } from "src/styles/newColors"
import styled from "styled-components"

const VetorOne = "https://central-imagens.bancointer.com.br/images-without-small-versions/one-dobra2-grafismo/image.webp"

export const SectionVocePodeMais = styled.section`
  min-height: 914px;
  background-color: ${graphiteOne};
  background-image: url(${VetorOne}), url(${VetorOne});
  background-position: top 175px left -287px, top 177px right -283px;
  background-size: 312px, 308px;
  background-repeat: no-repeat, no-repeat;
  padding: 40px 0;
  
  @media ${device.tablet} { 
    min-height: 542px;
    background-size: 337px;
    background-position: top 150px left -289px;
  }
  @media ${device.desktopLG} {
    min-height: 672px;
    background-size: 417px;
    background-position: top 174px left -338px;
    padding-top: 64px
  }
  @media ${device.desktopXL} {
    min-height: 867px;
    background-size: 552px;
    background-position: top 220px left -476px;
    padding-top: 96px
  }
  @media ${device.desktopXXL} {
    background-position: top 220px left -403px;
  }

  button, a {
    max-width: 312px;

    @media ${device.desktopLG} {
      max-width: 455px;
    }
  }
`
