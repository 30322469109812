import React, { Fragment, useEffect, useState } from "react"
import useDataLayer from "src/hooks/useDataLayer/dataLayerBody"

import { CarouselSection, ImageBanner, MainBanner, Nav, StepCard } from "./styles"
import { CarouselSectionProps, DataPropsCarousel, StepProps } from "./types"

const CarouselSectionBanner = ({
  sectionCarouselData, dobra, setDobra, setOpenModal, setSelectType,
}: DataPropsCarousel) => {
  const tabName = sectionCarouselData.items.map((item: CarouselSectionProps) => item.steps.map((step: { type: string }) => step.type))
  const itemsTab: StepProps[][] = sectionCarouselData.items.map((item: CarouselSectionProps) => item.steps)
  const titleBoxWhite = sectionCarouselData.titleBox

  const [ sendDatalayerEvent ] = useDataLayer()
  const [ currentIndex, setCurrentIndex ] = useState(0)

  if (tabName.length !== itemsTab.length) {
    return null
  }

  const handleTabClick = (index: number) => {
    setCurrentIndex(index)
  }

  useEffect(() => {
    const carouselElement = document.getElementById(`carousel-item-${currentIndex}`)

    if (carouselElement) {
      const parentElement = carouselElement.parentElement
      if (parentElement) {
        const parentRect = parentElement.getBoundingClientRect()
        const elementRect = carouselElement.getBoundingClientRect()
        const offset = elementRect.left - parentRect.left

        parentElement.scrollTo({
          left: parentElement.scrollLeft + offset,
          behavior: 'smooth',
        })
      }
    }

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex: number) => (prevIndex + 1) % sectionCarouselData.items.length)
    }, 12000)
    return () => clearInterval(interval)
  }, [ currentIndex, sectionCarouselData.items.length ])

  return (
    <MainBanner>
      <div className='slider'>
        {
          sectionCarouselData.items.map((item: CarouselSectionProps, index: number) => {
            return (
              <Fragment key={index}>
                <ImageBanner
                  bgImage={{
                    sm: item.bgImageMobile,
                    md: item.bgImageTablet,
                    lg: item.bgImageDesktopLG,
                    xl: item.bgImageDesktopXL,
                    xxl: item.bgImageDesktopXXL,
                    xxxl: item.bgImageDesktopXXXL,
                  }}
                  aria-label={item.ariaLabel}
                  role='img'
                  id={`carousel-item-${index}`}
                  className={`carousel-item ${index === currentIndex ? 'active' : ''}`}
                />
              </Fragment>
            )
          })
        }
        <CarouselSection>
          <StepCard>
            <h2
              className='font-citrina fs-24 lh-28 fs-md-28 lh-md-34 fs-lg-40 lh-lg-44 fs-xl-48 lh-xl-52 text-grayscale--500 mb-4'
              dangerouslySetInnerHTML={{ __html: titleBoxWhite }}
            />
            <Nav>
              {tabName.flat().map((item: string, index: number) => (
                <button
                  key={index}
                  className={`btn text-none ${currentIndex === index ? 'active' : ''}`}
                  onClick={() => {
                    handleTabClick(index)
                    setDobra && setDobra(dobra)
                    sendDatalayerEvent({
                      section: dobra,
                      section_name: sectionCarouselData.section + ' - ' + item,
                      element_action: 'click button',
                      element_name: item,
                    })
                  }}
                  title={item}
                >
                  {item}
                </button>
              ))}
            </Nav>

            {itemsTab[currentIndex].map((step: StepProps, stepIndex: number) => (
              <div key={stepIndex}>
                <p
                  className='fs-14 lh-17 fs-md-16 lh-md-20 fs-xl-18 lh-xl-22 text-grayscale--500 my-4 mt-xl-5'
                  dangerouslySetInnerHTML={{ __html: step.description }}
                />
                <button
                  title={step.textButton}
                  className='btn btn--lg text-white text-none mt-md-3 modal-button'
                  onClick={() => {
                    setSelectType && setSelectType(step.type)
                    setDobra && setDobra(dobra)
                    setOpenModal && setOpenModal(true)
                    sendDatalayerEvent({
                      section: dobra,
                      section_name: sectionCarouselData.section + ' - ' + step.type + ' - ' + step.textButton,
                      element_previous: step.type,
                      element_action: 'click button',
                      element_name: step.textButton,
                    })
                  }}
                >
                  {step.textButton}
                </button>
              </div>
            ))}
          </StepCard>
        </CarouselSection>
      </div>
    </MainBanner>
  )
}

export default CarouselSectionBanner
