import React, { useState } from 'react'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import { Modal } from 'src/components/Modal'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import useDomReady from 'src/hooks/window/useDomReady'
import { BoxLearnMore, SectionSaberMaisSobre } from './style'

import qrCodeModal from './../../assets/images/qr-code-area-exclusiva-super-app.png'

const QuerSaberMaisSobre = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const domReady = useDomReady()
  const [ isModalOpen, setIsModalOpen ] = useState(false)

  const openModal = (domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isModalOpen}
        setIsModal={setIsModalOpen}
        title='Acesse a Área Exclusiva do Super App'
        subtitle='Aponte a câmera do celular para o QR Code abaixo que você será direcionado para a Área Exclusiva'
        qrCode={qrCodeModal}
      />
    </Modal>
  ))

  return (
    <SectionSaberMaisSobre id='quer-saber-mais-sobre'>
      {openModal}
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6'>
            <h2 className='font-citrina text-white fs-24 lh-28 fs-md-28 lh-md-34 fs-xl-40 lh-xl-44 fw-600 mb-3'>
              Quer saber mais sobre o Inter One?
            </h2>
            <p className='text-white fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 mb-4'>
              Entre em contato com o nosso time para tirar dúvidas nos canais oficiais de atendimento.
            </p>
          </div>
          <div className='col-12 col-md-6'>
            <BoxLearnMore>
              <div className='d-flex align-items-center justify-content-start mb-2'>
                <div className='mr-2'>
                  <IconsSwitch
                    icon='ic_star'
                    color='#616161'
                    lib='interco'
                    size='MD'
                    customPath='action-navigation/'
                  />
                </div>
                <h3 className='font-sora fs-14 lh-17 fs-md-16 lh-md-20 text-grayscale--500 mb-0'>
                  Quero ser Inter One
                </h3>
              </div>
              <p className='fs-14 lh-17 fs-md-16 lh-md-20 text-grayscale--500 mb-0'>
                Entre em contato pelo telefone
                <a
                  href='tel:+553130034070'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_08',
                      element_action: 'click button',
                      element_name: ' 3003-4070',
                      section_name: 'Quer saber mais sobre o Inter One?',
                      redirect_url: 'tel:+553130034070',
                    })
                  }}
                > (31) 3003 - 4070
                </a>
              </p>
              <p className='fs-14 lh-17 fs-md-16 lh-md-20 text-grayscale--500 mb-0'>
                Envie uma mensagem no <a href='https://inter-co.onelink.me/Qyu7/gv242y6t' title='Chat Inter' target='_blank' rel='noreferrer'>chat</a>
              </p>
            </BoxLearnMore>
            <BoxLearnMore>
              <div className='d-flex align-items-center justify-content-start mb-2'>
                <div className='mr-2'>
                  <IconsSwitch
                    icon='ic_user'
                    color='#616161'
                    lib='interco'
                    size='MD'
                    customPath='human/'
                  />
                </div>
                <h3 className='font-sora fs-14 lh-17 fs-md-16 lh-md-20 text-grayscale--500 mb-0'>
                  Já sou cliente Inter One
                </h3>
              </div>
              <p className='fs-14 lh-17 fs-md-16 lh-md-20 text-grayscale--500 mb-0'>
                Entre em contato pelo telefone
                <a
                  href='tel:+553130034070'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_08',
                      element_action: 'click button',
                      element_name: ' 3003-4070',
                      section_name: 'Quer saber mais sobre o Inter One?',
                      redirect_url: 'tel:+553130034070',
                    })
                  }}
                > (31) 3003 - 4070
                </a>
              </p>
              <p className='fs-14 lh-17 fs-md-16 lh-md-20 text-grayscale--500 mb-0'>
                Envie uma mensagem para o seu advisor pela
                <span
                  title='Área Exclusiva'
                  className='cursor-pointer'
                  onClick={() => {
                    setIsModalOpen(true)
                    sendDatalayerEvent({
                      section: 'dobra_08',
                      element_action: 'click button',
                      element_name: ' 3003-4070',
                      section_name: 'Quer saber mais sobre o Inter One?',
                      redirect_url: 'https://inter-co.onelink.me/Qyu7/ri7l1wni',
                    })
                  }}
                > Área Exclusiva
                </span> do Super App
              </p>
            </BoxLearnMore>
          </div>
        </div>
      </div>
    </SectionSaberMaisSobre>
  )
}

export default QuerSaberMaisSobre
